import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const Footer = () => {
  const { t } = useTranslation()

  return (
    // <!-- Page Footer-->
    <footer className="section section-fluid footer-minimal context-dark">
      <div className="bg-gray-15 container-full-width">
        {/* <div className="footer-minimal-inset oh">
        <ul className="footer-list-category-2">
          <li>
            <a href="#">UI Design</a>
          </li>
          <li>
            <a href="#">Windows/Mac OS Apps</a>
          </li>
          <li>
            <a href="#">Android/iOS Apps</a>
          </li>
          <li>
            <a href="#">Cloud Solutions</a>
          </li>
          <li>
            <a href="#">Customer Support</a>
          </li>
        </ul>
      </div> */}
        <div className="container footer-minimal-bottom-panel text-sm-left">
          <div className="row row-10 align-items-md-center">
            <div className="col-sm-6 col-md-4 text-sm-right text-md-center">
              <div>
                <ul className="list-inline list-inline-sm footer-social-list-2">
                  <li className="rd-navbar-share-list-item">
                    <a
                      className="icon fa fa-facebook"
                      target="_blank"
                      href="https://www.facebook.com/mxcsoftware"
                      onClick={() => {
                        if (windows.gtag)
                          window.gtag("event", "facebook_clicked", {
                            source: "share_icon",
                            component: "footer",
                          })
                      }}
                    ></a>
                  </li>
                  <li className="rd-navbar-share-list-item">
                    <a
                      target="_blank"
                      className="icon fa fa-linkedin"
                      href="https://www.linkedin.com/company/mxcsoftware"
                      onClick={() => {
                        if (window.gtag)
                          window.gtag("event", "linkedin_clicked", {
                            source: "share_icon",
                            component: "footer",
                          })
                      }}
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 order-sm-first">
              {/* <!-- Rights--> */}
              <p className="rights">
                <span>&copy;&nbsp;</span>
                <span className="copyright-year"></span>{" "}
                <span>MXC Software</span>
              </p>
            </div>
            <div className="col-sm-6 col-md-4 text-md-right">
              {t("all_rights_reserved")}

              <p>
                <a className="" href="/privacy-policy">
                  {t("privacy_policy_title")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
