// import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo_transparent_background.png"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"

export function generateUrl(segment, location) {
  if (!location) return segment
  return location.startsWith("/#") || location === "/" ? segment : "/" + segment
}

const Header = ({ siteTitle, location }) => {
  const { t } = useTranslation()
  const { languages, originalPath } = useI18next()

  return (
    // <!-- Page Header-->
    <header className="section page-header" id="home">
      {/* <!-- RD Navbar--> */}
      <div className="rd-navbar-wrap">
        <nav
          className="rd-navbar rd-navbar-classic"
          data-layout="rd-navbar-fixed"
          data-sm-layout="rd-navbar-fixed"
          data-md-layout="rd-navbar-static"
          data-md-device-layout="rd-navbar-static"
          data-lg-layout="rd-navbar-static"
          data-lg-device-layout="rd-navbar-static"
          data-xl-layout="rd-navbar-static"
          data-xl-device-layout="rd-navbar-static"
          data-xxl-layout="rd-navbar-static"
          data-xxl-device-layout="rd-navbar-static"
          data-anchor-nav-offset="-56"
          data-sm-anchor-nav-offset="-56"
          data-md-anchor-nav-offset="-138"
          data-lg-anchor-nav-offset="-138"
          data-xl-anchor-nav-offset="-138"
          data-xxl-anchor-nav-offset="-138"
          data-lg-stick-up="true"
          data-xl-stick-up="true"
          data-xxl-stick-up="true"
        >
          <div className={"rd-navbar-main-outer"}>
            <div className="rd-navbar-main">
              {/* <!-- RD Navbar Panel--> */}
              <div className="rd-navbar-panel">
                {/* <!-- RD Navbar Toggle--> */}
                <button
                  className="rd-navbar-toggle"
                  data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                >
                  <span></span>
                </button>
                {/* <!-- RD Navbar Brand--> */}
                <div className="rd-navbar-brand">
                  <a className="brand" href="./">
                    <img
                      src={logo}
                      alt=""
                      height="90"
                      onClick={() =>
                        window.gtag("event", "logo_clicked", {
                          source: "logo",
                          component: "header",
                        })
                      }
                    />
                  </a>
                </div>
              </div>
              <div className="rd-navbar-main-element">
                <div className="rd-navbar-nav-wrap">
                  {/* <!-- RD Navbar lang--> */}
                  <div className="rd-navbar-lang">
                    <span
                      className="rd-navbar-lang-icon material-icons-language"
                      data-rd-navbar-toggle=".rd-navbar-lang-list"
                    ></span>
                    <ul className="list-inline rd-navbar-lang-list">
                      {languages.map(lng => (
                        <li key={lng} className="rd-navbar-lang-list-item">
                          <Link to={originalPath} language={lng}>
                            {lng.toUpperCase()}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <!-- RD Navbar share--> */}
                  <div className="rd-navbar-share">
                    <span
                      className="rd-navbar-share-icon fl-bigmug-line-share27"
                      data-rd-navbar-toggle=".rd-navbar-share-list"
                    ></span>
                    <ul className="list-inline rd-navbar-share-list">
                      <li className="rd-navbar-share-list-item">
                        <a
                          className="icon fa fa-facebook"
                          target="_blank"
                          href="https://www.facebook.com/mxcsoftware"
                          onClick={() => {
                            if (windows.gtag)
                              window.gtag("event", "facebook_clicked", {
                                source: "share_icon",
                                component: "header",
                              })
                          }}
                        ></a>
                      </li>
                      <li className="rd-navbar-share-list-item">
                        <a
                          target="_blank"
                          className="icon fa fa-linkedin"
                          href="https://www.linkedin.com/company/mxcsoftware"
                          onClick={() => {
                            if (window.gtag)
                              window.gtag("event", "linkedin_clicked", {
                                source: "share_icon",
                                component: "header",
                              })
                          }}
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <ul className="rd-navbar-nav">
                    <li className="rd-nav-item active">
                      <a
                        className="rd-nav-link"
                        href={generateUrl("#home", location)}
                        onClick={() => {
                          if (window.gtag)
                            window.gtag("event", "home_clicked", {
                              source: "menu",
                              component: "header",
                            })
                        }}
                      >
                        {t("home")}
                      </a>
                    </li>
                    <li className="rd-nav-item">
                      <a
                        className="rd-nav-link"
                        href={generateUrl("#services", location)}
                        onClick={() => {
                          if (window.gtag)
                            window.gtag("event", "services_clicked", {
                              source: "menu",
                              component: "header",
                            })
                        }}
                      >
                        {t("services")}
                      </a>
                    </li>
                    <li className="rd-nav-item">
                      <a
                        className="rd-nav-link"
                        href={generateUrl("#calculator", location)}
                        onClick={() => {
                          if (window.gtag)
                            window.gtag("event", "calculator_clicked", {
                              source: "menu",
                              component: "header",
                            })
                        }}
                      >
                        {t("price_calculator")}
                      </a>
                    </li>
                    <li className="rd-nav-item">
                      <a
                        className="rd-nav-link"
                        href={generateUrl("#projects", location)}
                        onClick={() => {
                          if (window.gtag)
                            window.gtag("event", "project_clicked", {
                              source: "menu",
                              component: "header",
                            })
                        }}
                      >
                        {t("projects")}
                      </a>
                    </li>
                    {/* <li className="rd-nav-item">
                    <a className="rd-nav-link" href="#team">
                      {t("team")}
                    </a>
                  </li> */}
                    <li className="rd-nav-item">
                      <a
                        className="rd-nav-link"
                        href={generateUrl("#contacts", location)}
                        onClick={() => {
                          if (window?.gtag)
                            window.gtag("event", "contact_clicked", {
                              source: "menu",
                              component: "header",
                            })
                        }}
                      >
                        {t("contacts")}
                      </a>
                    </li>
                    <li className="rd-nav-item">
                      <a
                        className="rd-nav-link"
                        href={generateUrl("career/#positions", location)}
                        onClick={() => {
                          if (window?.gtag)
                            window.gtag("event", "career_clicked", {
                              source: "menu",
                              component: "header",
                            })
                        }}
                      >
                        {t("career")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
