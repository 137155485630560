/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import Header from "./header"
import Swiper from "./swiper"
import ContactForm from "./contact-form"
import Footer from "./footer"
// import Services from "./Services"

const Layout = ({ changeLanguage, children, location, swiper = true }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  const { t } = useTranslation()

  return (
    <>
      <div className="preloader" id="preloader">
        <div className="preloader-body">
          <div className="cssload-container">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className="page animated">
        <Header location={location} changeLanguage={changeLanguage} />
        {swiper ? <Swiper /> : null}
        {children}
        {/* <ContactForm /> */}
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
