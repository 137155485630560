import React from "react"
import mobileImg from "../images/slider-1-slide-2-1770x742.jpg"
import teamImg from "../images/slider-1-slide-4-1770x742.jpg"
import softwareImg from "../images/slider-1-slide-6-1770x742.jpg"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const Swiper = () => {
  const { t } = useTranslation()

  return (
    // <!-- Swiper-->
    <section
      className="section swiper-container swiper-slider swiper-slider-classic"
      data-loop="true"
      data-autoplay="4859"
      data-simulate-touch="true"
      data-direction="vertical"
      data-nav="false"
    >
      <div className="swiper-wrapper text-center">
        <div className="swiper-slide" data-slide-bg={mobileImg}>
          <div className="swiper-slide-caption section-md">
            <div className="container">
              <h1 data-caption-animate="fadeInLeft" data-caption-delay="0">
                {t("development_title")}
              </h1>
              <p
                className="text-width-large"
                data-caption-animate="fadeInRight"
                data-caption-delay="100"
              >
                {t("development_description")}
              </p>
              <a
                className="button button-primary button-ujarak"
                href="#calculator"
                data-caption-animate="fadeInUp"
                data-caption-delay="200"
                onClick={event => {
                  event.preventDefault()

                  if (window.gtag) {
                    window.gtag("event", "calculator_clicked", {
                      source: "swiper_1",
                      component: "swiper",
                    })
                  }
                  var o = $("#calculator").offset()
                  var sT = o.top - $(".page-header").outerHeight(true)
                  window.scrollTo({
                    top: sT + 20,
                    left: 0,
                    behavior: "smooth",
                  })
                }}
              >
                {t("price_calculator")}
              </a>
            </div>
          </div>
        </div>
        <div className="swiper-slide" data-slide-bg={teamImg}>
          <div className="swiper-slide-caption section-md">
            <div className="container">
              <h1 data-caption-animate="fadeInLeft" data-caption-delay="0">
                {t("team_title")}
              </h1>
              <p
                className="text-width-large"
                data-caption-animate="fadeInRight"
                data-caption-delay="100"
              >
                {t("team_description")}
              </p>
              <a
                className="button button-primary button-ujarak"
                href="#calculator"
                data-caption-animate="fadeInUp"
                data-caption-delay="200"
                onClick={event => {
                  event.preventDefault()

                  if (window.gtag) {
                    window.gtag("event", "calculator_clicked", {
                      source: "swiper_2",
                      component: "swiper",
                    })
                  }
                  var o = $("#calculator").offset()
                  var sT = o.top - $(".page-header").outerHeight(true)
                  window.scrollTo({
                    top: sT + 20,
                    left: 0,
                    behavior: "smooth",
                  })
                }}
              >
                {t("price_calculator")}
              </a>
            </div>
          </div>
        </div>
        <div className="swiper-slide" data-slide-bg={softwareImg}>
          <div className="swiper-slide-caption section-md">
            <div className="container">
              <h1 data-caption-animate="fadeInLeft" data-caption-delay="0">
                {t("awards_title")}
              </h1>
              <p
                className="text-width-large"
                data-caption-animate="fadeInRight"
                data-caption-delay="100"
              >
                {t("awarsd_description")}
              </p>
              <a
                className="button button-primary button-ujarak"
                href="#calculator"
                data-caption-animate="fadeInUp"
                data-caption-delay="200"
                onClick={event => {
                  event.preventDefault()

                  if (window.gtag) {
                    window.gtag("event", "calculator_clicked", {
                      source: "swiper_3",
                      component: "swiper",
                    })
                  }
                  var o = $("#calculator").offset()
                  var sT = o.top - $(".page-header").outerHeight(true)
                  window.scrollTo({
                    top: sT + 20,
                    left: 0,
                    behavior: "smooth",
                  })
                }}
              >
                {t("price_calculator")}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Swiper Pagination--> */}
      <div className="swiper-pagination__module">
        <div className="swiper-pagination__fraction">
          <span className="swiper-pagination__fraction-index">00</span>
          <span className="swiper-pagination__fraction-divider">/</span>
          <span className="swiper-pagination__fraction-count">00</span>
        </div>
        <div className="swiper-pagination__divider"></div>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  )
}

export default Swiper
